import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { IApplicationState } from "../../../store";
import { resetStoreState, setCurrentPage } from "../../../store/Flow/actions";
import { IFlowState } from "../../../store/Flow/types";
import { ILayoutDataState } from "../../../store/LayoutData/types";
import { ILocalizationState } from "../../../store/Localization/types";
import StylesUtil from "../../../utils/StylesUtil";
import "./index.css";

interface IDispatchProps {
  resetStoreState: typeof resetStoreState;
  setCurrentPage: typeof setCurrentPage;
}

type Props = IDispatchProps &
  IFlowState &
  ILocalizationState &
  ILayoutDataState;

class HomeButton extends React.Component<Props> {
  private buttonClick = () => {
    const { setCurrentPage } = this.props;

    setCurrentPage("LastInOfficePage" as PageType);
  };

  render() {
    const { layoutData, currentPage } = this.props;

    if (currentPage === "FrontPage" && layoutData.code === "CSolutionHouse") {
      return <div></div>;
      //   <div onClick={this.buttonClick}>
      //     <img
      //       style={StylesUtil.getLanguageButtonStyle(layoutData)}
      //       src={window.location.origin + "/images/Cockwheel.png"}
      //       alt="Settings button"
      //     />
      //   </div>;
    } else {
      return <div></div>;
    }
  }
}

const mapStateToProps = (
  state: IApplicationState
): Partial<IFlowState & ILocalizationState & ILayoutDataState> => {
  const { currentPage } = state.flow;
  const { language } = state.localization;
  const { layoutData, layoutsRedirect } = state.layoutData;

  return {
    language,
    layoutData,
    layoutsRedirect,
    currentPage,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): IDispatchProps => {
  return bindActionCreators({ resetStoreState, setCurrentPage }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(HomeButton);
