import React, { Component, CSSProperties } from "react";
import {
  IButtonStyles,
  ISearchBoxStyles,
  ISpinnerStyles,
  ITextFieldStyles,
} from "@fluentui/react";
import ClientConfig from "../config/clientConfig";

export default class RedirectUtil extends Component {
  //#region Generic
  /*---------------*/
  static getButtonStyles = (
    layoutData: CheckInLayoutDTO
  ): Partial<IButtonStyles> => {
    const borderColor =
      ClientConfig.secondaryColor === "#000000"
        ? "1px solid white"
        : ClientConfig.secondaryColor === "#ffffff"
        ? "1px solid black"
        : "0px";
    if (layoutData.code === "SociatyOfLife") {
      return {
        label: {
          fontSize: "30px",
          fontFamily: "Roboto",
          fontWeight: 300,
        },
        root: {
          height: "80px",
          width: "200px",
          border: borderColor,
          backgroundColor: ClientConfig.secondaryColor,
          color: ClientConfig.secondaryTextColor,
        },
        rootHovered: {
          border: borderColor,
          backgroundColor: ClientConfig.secondaryColor,
          color: ClientConfig.secondaryTextColor,
        },
        rootPressed: {
          border: borderColor,
          backgroundColor: ClientConfig.secondaryColor,
          color: ClientConfig.secondaryTextColor,
        },
      };
    } else if (layoutData.code === "OfficePartner") {
      return {
        label: {
          fontSize: "20px",
          fontFamily: "Roboto",
          fontWeight: 300,
        },
        root: {
          height: "40px",
          width: "100px",
          borderRadius: "10px",
          border: "2px solid grey",
          backgroundColor: ClientConfig.secondaryColor,
          color: ClientConfig.secondaryTextColor,
        },
        rootHovered: {
          borderRadius: "10px",
          border: "2px solid grey",
          backgroundColor: ClientConfig.secondaryColor,
          color: ClientConfig.secondaryTextColor,
        },
        rootPressed: {
          borderRadius: "10px",
          border: "2px solid grey",
          backgroundColor: ClientConfig.secondaryColor,
          color: ClientConfig.secondaryTextColor,
        },
      };
    } else if (layoutData.code === "Dragsbaek") {
      return {
        label: {
          fontSize: "3.5vh",
          fontFamily: "Roboto",
          fontWeight: 300,
        },
        root: {
          height: "8vh",
          width: "8vw",
          border: borderColor,
          backgroundColor: ClientConfig.secondaryColor,
          color: ClientConfig.secondaryTextColor,
        },
        rootHovered: {
          border: borderColor,
          backgroundColor: ClientConfig.secondaryColor,
          color: ClientConfig.secondaryTextColor,
        },
        rootPressed: {
          border: borderColor,
          backgroundColor: ClientConfig.secondaryColor,
          color: ClientConfig.secondaryTextColor,
        },
      };
    } else if (layoutData.code === "Kjaergaard") {
      return {
        label: {
          fontSize: "40px",
          fontWeight: 400,
        },
        root: {
          height: "80px",
          width: "200px",
          border: borderColor,
          backgroundColor: ClientConfig.secondaryColor,
          color: ClientConfig.secondaryTextColor,
        },
        rootHovered: {
          border: borderColor,
          backgroundColor: ClientConfig.secondaryColor,
          color: ClientConfig.secondaryTextColor,
        },
        rootPressed: {
          border: borderColor,
          backgroundColor: ClientConfig.secondaryColor,
          color: ClientConfig.secondaryTextColor,
        },
      };
    } else if (
      layoutData.code === "Flensted" ||
      layoutData.code === "FlenstedWorkshop"
    ) {
      return {
        label: {
          fontSize: "27px",
          fontFamily: "Roboto",
          fontWeight: 300,
        },
        root: {
          height: "68px",
          width: "152px",
          border: borderColor,
          borderRadius: "16px",
          backgroundColor: ClientConfig.secondaryColor,
          color: ClientConfig.secondaryTextColor,
        },
        rootHovered: {
          border: borderColor,
          backgroundColor: ClientConfig.secondaryColor,
          color: ClientConfig.secondaryTextColor,
        },
        rootPressed: {
          border: borderColor,
          backgroundColor: ClientConfig.secondaryColor,
          color: ClientConfig.secondaryTextColor,
        },
      };
    } else if (
      layoutData.code === "Lauritzen" ||
      layoutData.code === "Bulkers"
    ) {
      return {
        label: {
          fontSize: "27px",
          fontFamily: "Calibri",
          fontWeight: 300,
        },
        root: {
          height: "68px",
          width: "152px",
          border: borderColor,
          backgroundColor: "transparent",
          backgroundImage: `url(${"/images/TransparentButton.png"})`,
          backgroundSize: "100% 100%",
          color: ClientConfig.secondaryTextColor,
        },
        rootHovered: {
          border: borderColor,
          backgroundColor: "transparent",
          backgroundImage: `url(${"/images/TransparentButton.png"})`,
          backgroundSize: "100% 100%",
          color: ClientConfig.secondaryTextColor,
        },
        rootPressed: {
          border: borderColor,
          backgroundColor: "transparent",
          backgroundImage: `url(${"/images/TransparentButton.png"})`,
          backgroundSize: "100% 100%",
          color: ClientConfig.secondaryTextColor,
        },
      };
    } else {
      return {
        label: {
          fontSize: "27px",
          fontFamily: "Roboto",
          fontWeight: 300,
        },
        root: {
          height: "68px",
          width: "152px",
          border: borderColor,
          backgroundColor: ClientConfig.secondaryColor,
          color: ClientConfig.secondaryTextColor,
        },
        rootHovered: {
          border: borderColor,
          backgroundColor: ClientConfig.secondaryColor,
          color: ClientConfig.secondaryTextColor,
        },
        rootPressed: {
          border: borderColor,
          backgroundColor: ClientConfig.secondaryColor,
          color: ClientConfig.secondaryTextColor,
        },
      };
    }
  };

  static getTextFieldStyles = (
    layoutData: CheckInLayoutDTO,
    highlight: boolean
  ): Partial<ITextFieldStyles> => {
    if (layoutData.code === "Reisswolf") {
      return {
        wrapper: {
          selectors: {
            label: {
              fontSize: "35px",
              color: ClientConfig.tertiaryTextColor,
            },
          },
        },
        fieldGroup: {
          width: "850px",
          height: "70px",
          borderRadius: "20px",
          backgroundColor: highlight ? "#A5BEFF" : "white",
        },
        field: {
          fontSize: "35px",
          fontFamily: "Roboto",
          textAlign: "center",
        },
      };
    } else if (layoutData.code === "Dragsbaek") {
      return {
        wrapper: {
          selectors: {
            label: {
              fontSize: "5vh",
              color: ClientConfig.tertiaryTextColor,
            },
          },
        },
        fieldGroup: {
          width: "40vw",
          height: "10vh",
          backgroundColor: highlight ? "#A5BEFF" : "white",
        },
        field: {
          fontSize: "5vh",
          fontFamily: "Roboto",
          textAlign: "center",
        },
      };
    } else if (
      layoutData.code === "Retograad" ||
      layoutData.code === "SociatyOfLife"
    ) {
      return {
        wrapper: {
          selectors: {
            label: {
              fontSize: "25px",
              color: ClientConfig.tertiaryTextColor,
            },
          },
        },
        fieldGroup: {
          width: "500px",
          height: "70px",
          backgroundColor: highlight ? "#A5BEFF" : "white",
        },
        field: {
          fontSize: "25px",
          fontFamily: "Roboto",
          textAlign: "center",
        },
      };
    } else if (
      layoutData.code === "Lauritzen" ||
      layoutData.code === "Bulkers"
    ) {
      return {
        wrapper: {
          selectors: {
            label: {
              fontSize: "22px",
              color: ClientConfig.tertiaryTextColor,
            },
          },
        },
        fieldGroup: {
          width: "1200px",
          height: "70px",
          overflow: "hidden",
          backgroundColor: "transparent",
          backgroundImage: highlight
            ? `url(${"/images/TransparentButtonBlue.png"})`
            : `url(${"/images/TransparentButton.png"})`,
          backgroundSize: "100% 100%",
        },
        field: {
          fontSize: "22px",
          fontFamily: "Calibri",
          textAlign: "center",
        },
      };
    } else if (layoutData.code === "BirchEjendomme") {
      return {
        wrapper: {
          selectors: {
            label: {
              fontSize: "35px",
              color: ClientConfig.tertiaryTextColor,
            },
          },
        },
        fieldGroup: {
          width: "550px",
          height: "70px",
          backgroundColor: highlight ? "#A5BEFF" : "white",
        },
        field: {
          fontSize: "35px",
          fontFamily: "Roboto",
          textAlign: "center",
        },
      };
    } else if (layoutData.code === "TotalEnergies") {
      return {
        wrapper: {
          selectors: {
            label: {
              fontSize: "35px",
              color: ClientConfig.tertiaryTextColor,
            },
          },
        },
        fieldGroup: {
          width: "70%",
          height: "70px",
          backgroundColor: highlight ? "#A5BEFF" : "white",
          margin: "auto",
        },
        field: {
          fontSize: "35px",
          fontFamily: "Roboto",
          textAlign: "center",
        },
      };
    } else if (layoutData.code === "DSStaal") {
      return {
        wrapper: {
          selectors: {
            label: {
              fontSize: "35px",
              color: "#FFFFFF",
            },
          },
        },
        fieldGroup: {
          width: "650px",
          height: "70px",
          backgroundColor: highlight ? "#A5BEFF" : "white",
        },
        field: {
          fontSize: "35px",
          fontFamily: "Roboto",
          textAlign: "center",
        },
      };
    } else if (layoutData.code === "FriskSnit") {
      return {
        wrapper: {
          selectors: {
            label: {
              fontSize: "35px",
              color: "#FFFFFF",
            },
          },
        },
        fieldGroup: {
          width: "650px",
          height: "70px",
          backgroundColor: highlight ? "#A5BEFF" : "white",
        },
        field: {
          fontSize: "35px",
          fontFamily: "Roboto",
          textAlign: "center",
        },
      };
    } else {
      return {
        wrapper: {
          selectors: {
            label: {
              fontSize: "35px",
              color: ClientConfig.tertiaryTextColor,
            },
          },
        },
        fieldGroup: {
          width: "650px",
          height: "70px",
          backgroundColor: highlight ? "#A5BEFF" : "white",
        },
        field: {
          fontSize: "35px",
          fontFamily: "Roboto",
          textAlign: "center",
        },
      };
    }
  };

  static getSearchBoxStyles = (
    layoutData: CheckInLayoutDTO
  ): Partial<ISearchBoxStyles> => {
    if (layoutData.code === "Reisswolf") {
      return {
        root: {
          marginTop: "-30px",
          width: "850px",
          height: "70px",
          borderRadius: "20px",
        },
        field: {
          fontSize: "35px",
          fontFamily: "Roboto",
          textAlign: "center",
        },
      };
    } else if (layoutData.code === "SociatyOfLife") {
      return {
        root: {
          width: "650px",
          height: "50px",
        },
        field: {
          fontSize: "25px",
          fontFamily: "Roboto",
          textAlign: "left",
        },
      };
    } else if (layoutData.code === "Dragsbaek") {
      return {
        root: {
          width: "40vw",
          height: "10vh",
        },
        field: {
          fontSize: "5vh",
          fontFamily: "Roboto",
          textAlign: "center",
        },
      };
    } else if (layoutData.code === "Retograad") {
      return {
        root: {
          width: "500px",
          height: "70px",
        },
        field: {
          fontSize: "25px",
          fontFamily: "Roboto",
          textAlign: "center",
        },
      };
    } else if (layoutData.code === "OfficePartner") {
      return {
        root: {
          position: "absolute",
          top: "20%",
          width: "650px",
          height: "70px",
        },
        field: {
          fontSize: "35px",
          fontFamily: "Roboto",
          textAlign: "center",
        },
      };
    } else if (layoutData.code === "EDForhandler") {
      return {
        root: {
          position: "absolute",
          top: "-7%",
          width: "650px",
          height: "70px",
        },
        field: {
          fontSize: "35px",
          fontFamily: "Roboto",
          textAlign: "center",
        },
      };
    } else if (
      layoutData.code === "Lauritzen" ||
      layoutData.code === "Bulkers"
    ) {
      return {
        root: {
          width: "650px",
          height: "70px",
          backgroundColor: "transparent",
          backgroundImage: `url(${"/images/TransparentButton.png"})`,
          backgroundSize: "100% 100%",
        },
        field: {
          fontSize: "22px",
          fontFamily: "Calibri",
          textAlign: "center",
        },
      };
    } else if (layoutData.code === "BirchEjendomme") {
      return {
        root: {
          width: "500px",
          height: "70px",
        },
        field: {
          fontSize: "35px",
          fontFamily: "Roboto",
          textAlign: "center",
        },
      };
    } else {
      return {
        root: {
          width: "650px",
          height: "70px",
        },
        field: {
          fontSize: "35px",
          fontFamily: "Roboto",
          textAlign: "center",
        },
      };
    }
  };

  static getSpinnerStyles = (
    layoutData: CheckInLayoutDTO
  ): Partial<ISpinnerStyles> => {
    if (layoutData.code === "Dragsbaek") {
      return {
        circle: {
          width: "15vh",
          height: "15vh",
          borderWidth: "0.6vh",
          borderTopColor: ClientConfig.secondaryColor,
          borderBottomColor: "rgb(199, 224, 244)",
          borderLeftColor: "rgb(199, 224, 244)",
          borderRightColor: "rgb(199, 224, 244)",
        },
      };
    } else {
      return {
        circle: {
          width: "150px",
          height: "150px",
          borderWidth: "6px",
          borderTopColor: ClientConfig.secondaryColor,
          borderBottomColor: "rgb(199, 224, 244)",
          borderLeftColor: "rgb(199, 224, 244)",
          borderRightColor: "rgb(199, 224, 244)",
        },
      };
    }
  };

  static getCheckMarkStyle = (layoutData: CheckInLayoutDTO): CSSProperties => {
    return {
      width: "100%",
      height: "100%",
      accentColor: ClientConfig.secondaryColor,
    };
  };

  static getMessageStyle = (
    layoutData: CheckInLayoutDTO,
    addedProperties: CSSProperties
  ): CSSProperties => {
    if (layoutData.code === "SociatyOfLife") {
      return {
        whiteSpace: "pre-line", // Makes new-line work for texts
        fontSize: "40px",
        textAlign: "center",
        fontWeight: "normal",
        color: "#707070",
        ...addedProperties,
      };
    } else if (layoutData.code === "Fundamentet") {
      return {
        whiteSpace: "pre-line", // Makes new-line work for texts
        fontWeight: "bold",
        fontSize: 30,
        textAlign: "center",
        color: ClientConfig.tertiaryTextColor,
        ...addedProperties,
      };
    } else if (layoutData.code === "Dragsbaek") {
      return {
        whiteSpace: "pre-line", // Makes new-line work for texts
        fontWeight: "bold",
        fontSize: "4vh",
        textAlign: "center",
        color: ClientConfig.tertiaryTextColor,
        ...addedProperties,
      };
    } else {
      return {
        whiteSpace: "pre-line", // Makes new-line work for texts
        fontWeight: "bold",
        fontSize: "40px",
        textAlign: "center",
        color: ClientConfig.tertiaryTextColor,
        ...addedProperties,
      };
    }
  };
  // #endregion

  //#region Content Area
  /*--------------------*/
  static getContentContainerStyle = (
    layoutData: CheckInLayoutDTO,
    currentPage: PageType
  ): CSSProperties => {
    if (layoutData.code === "Dragsbaek") {
      return {
        height: "100%",
        width: "100%",
        display: "flex",
        flexFlow: "row nowrap",
        justifyContent: "center",
        marginTop: "0.5vh",
      };
    } else if (
      currentPage === "EmployeePage" &&
      (layoutData.code === "Flensted" || layoutData.code === "FlenstedWorkshop")
    ) {
      return {
        height: "630px",
        width: "100%",
        display: "flex",
        flexFlow: "row nowrap",
        justifyContent: "center",
        marginTop: "5px",
      };
    } else if (
      layoutData.code === "FibiaHaslevHouse" ||
      layoutData.code === "Meneta" ||
      layoutData.code === "BirchEjendomme"
    ) {
      return {
        paddingTop: "2%",
        paddingBottom: "2%",
        paddingLeft: "7%",
        paddingRight: "7%",
      };
    } else {
      return {
        height: "800px",
        width: "100%",
        display: "flex",
        flexFlow: "row nowrap",
        justifyContent: "center",
        marginTop: "5px",
      };
    }
  };

  static getContentPageContainerStyle = (
    layoutData: CheckInLayoutDTO,
    currentPage: PageType
  ): CSSProperties => {
    if (currentPage === "ConditionPdfPage") {
      return {
        display: "flex",
        flexDirection: "column",
        flexWrap: "nowrap",
        width: "1500px",
        height: "800px",
      };
    } else if (layoutData.code === "Dragsbaek") {
      return {
        display: "flex",
        flexDirection: "column",
        flexWrap: "nowrap",
        width: "100%",
        height: "60vh",
      };
    } else if (layoutData.code === "EDForhandler") {
      return {
        position: "absolute",
        top: "15%",
        display: "flex",
        flexDirection: "column",
        flexWrap: "nowrap",
        width: "1500px",
        height: "500px",
      };
    } else if (
      layoutData.code === "Flensted" ||
      layoutData.code === "FlenstedWorkshop"
    ) {
      if (currentPage === "FrontPage") {
        return {
          display: "flex",
          flexDirection: "column",
          flexWrap: "nowrap",
          width: "1500px",
          height: "565px",
        };
      } else if (currentPage === "EmployeePage") {
        return {
          display: "flex",
          flexDirection: "column",
          flexWrap: "nowrap",
          width: "1500px",
          height: "700px",
          marginBottom: "460px",
          backgroundColor: ClientConfig.mainColor,
          borderRadius: "16px",
          border: "2px solid black",
          padding: "16px 32px",
        };
      } else {
        return {
          display: "flex",
          flexDirection: "column",
          flexWrap: "nowrap",
          width: "1500px",
          height: "700px",
          marginBottom: "460px",
          backgroundColor: ClientConfig.mainColor,
          borderRadius: "16px",
          border: "2px solid black",
          padding: "16px 32px",
        };
      }
    } else if (
      layoutData.code === "Lauritzen" ||
      layoutData.code === "Bulkers"
    ) {
      return {
        display: "flex",
        flexDirection: "column",
        flexWrap: "nowrap",
        width: "1500px",
        height: "800px",
      };
    } else if (
      layoutData.code === "FibiaHaslevHouse" ||
      layoutData.code === "Meneta"
    ) {
      return {
        display: "flex",
        flexDirection: "column",
        flexWrap: "nowrap",
        width: "100%",
        marginTop: "0",
      };
    } else if (layoutData.code === "BirchEjendomme") {
      return {
        display: "flex",
        flexDirection: "column",
        flexWrap: "nowrap",
        width: "100%",
        //paddingLeft: '7%',
        //paddingRight: '7%',
        marginTop: "0",
      };
    } else if (layoutData.code === "TotalEnergies") {
      return {
        //display: 'flex',
        //flexDirection: 'column',
        //flexWrap: 'nowrap',
        width: "66%",
        height: "75%",
        position: "absolute",
        left: "0px",
        transform: "translateY(-50%)",
        top: "50%",
      };
    } else if (layoutData.code === "DSStaal") {
      return {
        display: "flex",
        flexDirection: "column",
        flexWrap: "nowrap",
        width: "1500px",
        height: "500px",
        marginBottom: "200px",
      };
    } else {
      return {
        display: "flex",
        flexDirection: "column",
        flexWrap: "nowrap",
        width: "1500px",
        height: "500px",
      };
    }
  };
  // #endregion

  //#region Flow Cards
  /*------------------*/
  static getFlowCardContainerStyle = (
    layoutData: CheckInLayoutDTO
  ): CSSProperties => {
    const borderColor =
      ClientConfig.mainColor === "#000000"
        ? "1px solid white"
        : ClientConfig.mainColor === "#ffffff"
        ? "1px solid black"
        : "0px";
    if (layoutData.code === "Dragsbaek") {
      return {
        width: "18vw",
        height: "28vh",
        display: "flex",
        alignItems: "center",
        flexFlow: "column nowrap",
        transition: "all .4s ease",
        margin: "4vw",
        cursor: "pointer",
        paddingTop: "4vh",
        background: ClientConfig.mainColor,
        border: borderColor,
        color: ClientConfig.mainTextColor,
      };
    } else if (
      layoutData.code === "Flensted" ||
      layoutData.code === "FlenstedWorkshop"
    ) {
      return {
        width: "275px",
        height: "150px",
        display: "flex",
        alignItems: "center",
        flexFlow: "row nowrap",
        transition: "all .4s ease",
        margin: "auto",
        cursor: "pointer",
        borderRadius: "16px",
        background: ClientConfig.mainColor,
        border: borderColor,
        color: ClientConfig.mainTextColor,
      };
    } else if (layoutData.code === "SociatyOfLife") {
      return {
        background: ClientConfig.mainColor,
        border: borderColor,
        color: ClientConfig.mainTextColor,
        width: "30%",
        height: "38%",
        margin: "25px",
        display: "flex",
        alignItems: "center",
        flexFlow: "column nowrap",
        transition: "all .4s ease",
        cursor: "pointer",
        paddingTop: "40px",
      };
    } else if (layoutData.code === "Retograad") {
      return {
        background: ClientConfig.mainColor,
        border: borderColor,
        color: ClientConfig.mainTextColor,
        width: "200px",
        height: "50px",
        margin: "0px",
        display: "flex",
        alignItems: "center",
        flexFlow: "column nowrap",
        transition: "all .4s ease",
        cursor: "pointer",
        paddingTop: "40px",
      };
    } else if (layoutData.code === "EDForhandler") {
      return {
        background: ClientConfig.mainColor,
        border: borderColor,
        color: ClientConfig.mainTextColor,
        width: "270px",
        height: "120px",
        paddingTop: 45,
        paddingLeft: 5,
        paddingRight: 5,
        margin: "30px",
        borderRadius: "50px",
        display: "flex",
        alignItems: "center",
        flexFlow: "column nowrap",
        transition: "all .4s ease",
        cursor: "pointer",
      };
    } else if (layoutData.code === "OfficePartner") {
      return {
        background: ClientConfig.mainColor,
        border: "2px solid grey",
        color: ClientConfig.mainTextColor,
        borderRadius: "15px",
        height: "150px",
        width: "350px",
        paddingTop: "0px",
        paddingLeft: "0px",
        paddingRight: "0px",
        justifyContent: "center",
        position: "relative",
        top: "25%",
        margin: "15px",
        display: "flex",
        alignItems: "center",
        flexFlow: "column nowrap",
        transition: "all .4s ease",
        cursor: "pointer",
      };
    } else if (layoutData.code === "Fundamentet") {
      return {
        background: ClientConfig.mainColor,
        border: borderColor,
        color: ClientConfig.mainTextColor,
        width: "300px",
        margin: 10,
        height: "275px",
        display: "flex",
        alignItems: "center",
        flexFlow: "column nowrap",
        transition: "all .4s ease",
        cursor: "pointer",
        paddingTop: "40px",
      };
    } else if (
      layoutData.code === "Visionhouse" ||
      layoutData.code === "SSIDiagnosticaHouse" ||
      layoutData.code === "CSolutionHouse"
    ) {
      return {
        background: ClientConfig.mainColor,
        border: borderColor,
        color: ClientConfig.mainTextColor,
        height: "160px",
        width: "600px",
        display: "flex",
        alignItems: "center",
        flexFlow: "column nowrap",
        transition: "all .4s ease",
        margin: "60px",
        cursor: "pointer",
        paddingTop: "40px",
      };
    } else if (layoutData.code === "VismaHouse") {
      return {
        background: ClientConfig.mainColor,
        borderRadius: "40px",
        color: ClientConfig.mainTextColor,
        height: "80px",
        width: "600px",
        display: "flex",
        alignItems: "center",
        flexFlow: "column nowrap",
        transition: "all .4s ease",
        margin: "60px",
        cursor: "pointer",
        //paddingTop: '20px',
      };
    } else if (layoutData.code === "LauritzenHouse") {
      return {
        backgroundImage: `url(${"/images/TransparentButton.png"})`,
        backgroundSize: "100% 100%",
        color: ClientConfig.mainTextColor,
        padding: "10px",
        height: "60px",
        display: "flex",
        alignItems: "center",
        flexFlow: "column nowrap",
        transition: "all .4s ease",
        cursor: "pointer",
        fontSize: "20px",
        bottom: "120px",
        position: "absolute",
      };
    } else if (layoutData.code === "ErhvershusMidt") {
      return {
        borderRadius: "15px",
        width: "275px",
        height: "275px",
        display: "flex",
        alignItems: "center",
        flexFlow: "column nowrap",
        transition: "all .4s ease",
        margin: "60px",
        cursor: "pointer",
        paddingTop: "40px",
        background: ClientConfig.mainColor,
        border: borderColor,
        color: ClientConfig.mainTextColor,
      };
    } else if (layoutData.code === "Kjaergaard") {
      return {
        background: ClientConfig.mainColor,
        border: borderColor,
        color: ClientConfig.mainTextColor,
        height: "320px",
        width: "500px",
        display: "flex",
        alignItems: "center",
        flexFlow: "column nowrap",
        transition: "all .4s ease",
        margin: "60px",
        cursor: "pointer",
        paddingTop: "40px",
      };
    } else if (layoutData.code === "Reisswolf") {
      return {
        background: ClientConfig.mainColor,
        border: borderColor,
        color: ClientConfig.mainTextColor,
        borderRadius: "15px",
        position: "relative",
        top: 350,
        marginRight: 150,
        marginLeft: 150,
        width: 400,
        height: 150,
        padding: 0,
        display: "flex",
        alignItems: "center",
        flexFlow: "column nowrap",
        transition: "all .4s ease",
        margin: "60px",
        cursor: "pointer",
        paddingTop: "20px",
      };
    } else if (layoutData.code === "LouisPoulsen") {
      return {
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        flexFlow: "column nowrap",
        transition: "all .4s ease",
        cursor: "pointer",
        justifyContent: "center",
        borderRadius: "32px",
        background: ClientConfig.mainColor,
        border:
          ClientConfig.mainColor === "#ffffff"
            ? "8px double black"
            : ClientConfig.mainColor === "#000000"
            ? "8px double white"
            : "",
        color: ClientConfig.mainTextColor,
      };
    } else if (layoutData.code === "Haugen") {
      return {
        width: "275px",
        height: "125px",
        display: "flex",
        alignItems: "center",
        flexFlow: "row nowrap",
        transition: "all .4s ease",
        margin: "auto",
        cursor: "pointer",
        background: ClientConfig.mainColor,
        border: "1px solid white",
        color: ClientConfig.mainTextColor,
      };
    } else if (
      layoutData.code === "Lauritzen" ||
      layoutData.code === "Bulkers"
    ) {
      return {
        width: "275px",
        height: "125px",
        display: "flex",
        alignItems: "center",
        flexFlow: "row nowrap",
        transition: "all .4s ease",
        margin: "auto",
        cursor: "pointer",
        backgroundImage: `url(${"/images/TransparentButton.png"})`,
        backgroundSize: "100% 100%",
        border: borderColor,
        color: ClientConfig.mainTextColor,
      };
    } else if (layoutData.code === "Kilde") {
      return {
        width: "275px",
        height: "125px",
        display: "flex",
        alignItems: "center",
        flexFlow: "row nowrap",
        transition: "all .4s ease",
        margin: "auto",
        cursor: "pointer",
        backgroundImage: `url(${"/images/KildeButton2.png"})`,
        backgroundSize: "100% 100%",
        //border: borderColor,
        color: ClientConfig.mainTextColor,
      };
    } else if (layoutData.code === "FibiaHaslevHouse") {
      return {
        width: "300px",
        height: "200px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexFlow: "row nowrap",
        transition: "all .4s ease",
        margin: "2%",
        cursor: "pointer",
        background: ClientConfig.mainColor,
        border: "0.5px solid white",
        color: ClientConfig.mainTextColor,
      };
    } else if (layoutData.code === "Meneta") {
      return {
        width: "300px",
        height: "150px",
        margin: "2%",
        cursor: "pointer",
        background: ClientConfig.mainColor,
        border: borderColor,
        color: ClientConfig.mainTextColor,
      };
    } else if (layoutData.code === "TotalEnergies") {
      return {
        width: "275px",
        height: "125px",
        display: "flex",
        alignItems: "center",
        flexFlow: "column nowrap",
        transition: "all .4s ease",
        margin: "60px",
        cursor: "pointer",
        background: ClientConfig.mainColor,
        border: borderColor,
        borderRadius: "25px",
        borderWidth: "5px",
        color: ClientConfig.mainTextColor,
      };
    } else if (layoutData.code === "DSStaal") {
      return {
        width: "18vw",
        height: "275px",
        display: "flex",
        alignItems: "center",
        flexFlow: "column nowrap",
        transition: "all .4s ease",
        margin: "20px",
        cursor: "pointer",
        paddingTop: "40px",
        border: borderColor,
        color: ClientConfig.mainTextColor,
      };
    } else if (layoutData.code === "RadioABC") {
      return {
        width: "275px",
        height: "275px",
        display: "flex",
        alignItems: "center",
        flexFlow: "column nowrap",
        transition: "all .4s ease",
        margin: "60px",
        cursor: "pointer",
        paddingTop: "40px",
        background: ClientConfig.mainColor,
        border: borderColor,
        color: ClientConfig.mainTextColor,
        borderRadius: "20px",
      };
    } else {
      return {
        width: "275px",
        height: "275px",
        display: "flex",
        alignItems: "center",
        flexFlow: "column nowrap",
        transition: "all .4s ease",
        margin: "60px",
        cursor: "pointer",
        paddingTop: "40px",
        background: ClientConfig.mainColor,
        border: borderColor,
        color: ClientConfig.mainTextColor,
      };
    }
  };

  static getFlowCardTextStyle = (
    layoutData: CheckInLayoutDTO
  ): CSSProperties => {
    if (layoutData.code === "SociatyOfLife") {
      return {
        fontFamily: "Times New Roman, Times, Serif",
        fontWeight: 1,
        letterSpacing: "1px",
        padding: "15px",
        wordWrap: "break-word",
        fontSize: "28px",
        display: "flex",
        justifyContent: "center",
        textAlign: "center",
        cursor: "pointer",
      };
    } else if (layoutData.code === "Retograad") {
      return {
        verticalAlign: "top",
        height: "0px",
        margin: "-50px",
        paddingTop: "-50px",
        padding: "15px",
        wordWrap: "break-word",
        fontWeight: "bold",
        fontSize: "28px",
        display: "flex",
        justifyContent: "center",
        textAlign: "center",
        cursor: "pointer",
      };
    } else if (layoutData.code === "EDForhandler") {
      return {
        verticalAlign: "top",
        height: "0px",
        marginTop: "-45px",
        paddingTop: "-50px",
        padding: "15px",
        wordWrap: "break-word",
        fontWeight: "bold",
        fontSize: "28px",
        display: "flex",
        justifyContent: "center",
        textAlign: "center",
        cursor: "pointer",
      };
    } else if (layoutData.code === "Fundamentet") {
      return {
        fontSize: "20px",
        padding: "15px",
        wordWrap: "break-word",
        fontWeight: "bold",
        display: "flex",
        justifyContent: "center",
        textAlign: "center",
        cursor: "pointer",
      };
    } else if (layoutData.code === "Kjaergaard") {
      return {
        fontSize: "35px",
        padding: "15px",
        wordWrap: "break-word",
        fontWeight: "bold",
        display: "flex",
        justifyContent: "center",
        textAlign: "center",
        cursor: "pointer",
      };
    } else if (layoutData.code === "OfficePartner") {
      return {
        paddingLeft: 0,
        paddingRight: 0,
        padding: "15px",
        wordWrap: "break-word",
        fontWeight: "bold",
        fontSize: "28px",
        display: "flex",
        justifyContent: "center",
        textAlign: "center",
        cursor: "pointer",
      };
    } else if (layoutData.code === "Reisswolf") {
      return {
        fontFamily: "Inter",
        fontSize: 60,
        paddingTop: 28,
        fontWeight: 100,
        padding: "15px",
        wordWrap: "break-word",
        display: "flex",
        justifyContent: "center",
        textAlign: "center",
        cursor: "pointer",
      };
    } else if (layoutData.code === "Dragsbaek") {
      return {
        padding: "1.5vh",
        wordWrap: "break-word",
        fontWeight: "bold",
        fontSize: "4vh",
        display: "flex",
        justifyContent: "center",
        textAlign: "center",
        cursor: "pointer",
      };
    } else if (
      layoutData.code === "Flensted" ||
      layoutData.code === "FlenstedWorkshop"
    ) {
      return {
        padding: "15px",
        wordWrap: "break-word",
        fontWeight: "bold",
        fontSize: "28px",
        display: "flex",
        justifyContent: "center",
        textAlign: "center",
        cursor: "pointer",
        width: "100%",
      };
    } else if (
      layoutData.code === "Haugen" ||
      layoutData.code === "Lauritzen" ||
      layoutData.code === "Bulkers"
    ) {
      return {
        wordWrap: "break-word",
        fontWeight: "bold",
        fontSize: "22px",
        display: "flex",
        justifyContent: "center",
        textAlign: "center",
        cursor: "pointer",
        width: "100%",
      };
    } else if (layoutData.code === "Meneta") {
      return {
        wordWrap: "break-word",
        fontWeight: "bold",
        fontSize: "22px",
        display: "flex",
        justifyContent: "center",
        textAlign: "center",
        cursor: "pointer",
        width: "100%",
      };
    } else if (layoutData.code === "TotalEnergies") {
      return {
        wordWrap: "break-word",
        fontWeight: "bold",
        fontSize: "40px",
        cursor: "pointer",
        margin: "auto",
      };
    } else if (layoutData.code === "DSStaal") {
      return {
        wordWrap: "break-word",
        fontWeight: "bold",
        fontSize: "28px",
        display: "flex",
        justifyContent: "center",
        textAlign: "center",
        cursor: "pointer",
        width: "100%",
        border: "2px solid white",
        borderRadius: "10px",
        marginTop: "20px",
      };
    } else {
      return {
        padding: "15px",
        wordWrap: "break-word",
        fontWeight: "bold",
        fontSize: "28px",
        display: "flex",
        justifyContent: "center",
        textAlign: "center",
        cursor: "pointer",
      };
    }
  };

  static getHouseFlowCardcontainerStyle = (
    layoutData: String
  ): CSSProperties => {
    if (layoutData === "FibiaHaslevHouse") {
      return {
        width: "275px",
        height: "275px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexFlow: "column nowrap",
        transition: "all .4s ease",
        margin: "60px",
        cursor: "pointer",
      };
    } else {
      return {};
    }
  };

  static getCustomFlowCardcontainerStyle = (
    layoutData: CheckInLayoutDTO
  ): CSSProperties => {
    if (layoutData.code === "Meneta") {
      return {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-around",
      };
    } else {
      return {};
    }
  };
  // #endregion

  //#region Custom Field Area
  /*--------------------------*/
  static getCustomTextFieldsContainerStyle = (
    layoutData: CheckInLayoutDTO
  ): CSSProperties => {
    if (
      layoutData.code === "Flensted" ||
      layoutData.code === "FlenstedWorkshop"
    ) {
      return {
        display: "flex",
        flexFlow: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        overflowY: "auto",
        width: "100%",
        height: "100%",
      };
    } else if (layoutData.code === "Reisswolf") {
      return {
        display: "flex",
        flexFlow: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        width: "100%",
      };
    } else if (layoutData.code === "DSStaal") {
      return {
        display: "flex",
        flexFlow: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        overflowY: "auto",
        overflowX: "hidden",
        width: "100%",
        height: "220px",
      };
    } else {
      return {
        display: "flex",
        flexFlow: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        overflowY: "auto",
        overflowX: "hidden",
        width: "100%",
        height: "100%",
      };
    }
  };
  // #endregion

  //#region Employee Area
  /*----------------------*/
  static getEmployeePageStyle = (
    layoutData: CheckInLayoutDTO
  ): CSSProperties => {
    if (layoutData.code === "Dragsbaek") {
      return {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        height: "100%",
      };
    } else if (layoutData.code === "BirchEjendomme") {
      return {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        height: "100%",
      };
    } else if (layoutData.code === "TotalEnergies") {
      return {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        height: "900px",
      };
    } else {
      return {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        height: "800px",
      };
    }
  };

  static simpleEmployeeList = (
    layoutData: CheckInLayoutDTO,
    highlight: boolean
  ): CSSProperties => {
    if (layoutData.code === "Lauritzen" || layoutData.code === "Bulkers") {
      return {
        backgroundColor: "transparent",
        backgroundImage: highlight
          ? `url(${"/images/TransparentButtonBlue.png"})`
          : `url(${"/images/TransparentButton.png"})`,
        width: "950px",
        height: "70px",
        fontSize: "22px",
        fontFamily: "Calibri",
        textAlign: "center",
        borderBottom: "1px solid black",
        padding: "10px 0",
      };
    } else {
      return {
        backgroundColor: highlight ? "#A5BEFF" : "white",
        width: "1200px",
        height: "70px",
        fontSize: "35px",
        fontFamily: "Roboto",
        textAlign: "center",
        borderBottom: "1px solid black",
        padding: "10px 0",
      };
    }
  };

  // #endregion

  //#region Employee Selection
  /*--------------------------*/
  static getEmployeeContainerStyle(
    layoutData: CheckInLayoutDTO,
    hasPhoto: boolean
  ): CSSProperties {
    if (layoutData.code === "SociatyOfLife") {
      return {
        maxHeight: 300,
        width: 1300,
        overflowY: "hidden",
        overflowX: "hidden",
        position: "absolute",
        left: 308,
        top: 330,
        whiteSpace: "nowrap",
        marginTop: "20px",
        marginBottom: "20px",
      };
    } else if (layoutData.code === "Retograad") {
      return {
        height: 585,
        width: 1860,
        overflowY: "hidden",
        overflowX: "hidden",
        position: "absolute",
        left: 30,
        top: 445,
        whiteSpace: "nowrap",
        backgroundColor: "#ffffff",
        paddingLeft: 80,
        paddingRight: 80,
        paddingTop: 130,
        marginTop: "20px",
        marginBottom: "20px",
      };
    } else if (layoutData.code === "OfficePartner") {
      return {
        maxHeight: 260,
        width: 1010,
        overflowY: "hidden",
        overflowX: "hidden",
        position: "absolute",
        top: "25%",
        whiteSpace: "nowrap",
        marginTop: "20px",
        marginBottom: "20px",
      };
    } else if (
      ClientConfig.employeePhotoEnabled === false &&
      layoutData.code === "EDForhandler"
    ) {
      return {
        maxHeight: 260,
        width: 1300,
        overflowY: "hidden",
        overflowX: "hidden",
        position: "absolute",
        top: "2%",
        whiteSpace: "nowrap",
        marginTop: "20px",
        marginBottom: "20px",
      };
    } else if (layoutData.code === "EDForhandler") {
      return {
        height: 410,
        width: 1320,
        overflowY: "hidden",
        overflowX: "hidden",
        position: "absolute",
        top: "2%",
        whiteSpace: "nowrap",
        marginTop: "20px",
        marginBottom: "20px",
      };
    } else if (layoutData.code === "Fundamentet") {
      return {
        position: "relative",
        height: 350,
        top: 150,
        marginTop: "20px",
        marginBottom: "20px",
      };
    } else if (layoutData.code === "Dragsbaek") {
      return {
        height: hasPhoto ? "46vh" : "26.5vh",
        width: "75vw",
        overflowY: "hidden",
        overflowX: "hidden",
        position: "relative",
        whiteSpace: "nowrap",
        gap: "0",
        marginTop: hasPhoto ? "2vh" : "10vh",
        marginBottom: "20px",
      };
    } else if (layoutData.code === "BirchEjendomme") {
      return {
        height: "24.5vh",
        width: "80vw",
        overflowY: "hidden",
        overflowX: "hidden",
        position: "relative",
        whiteSpace: "nowrap",
        gap: "0",
      };
    } else if (layoutData.code === "TotalEnergies") {
      return {
        width: "90%",
        overflowY: "hidden",
        overflowX: "hidden",
        whiteSpace: "nowrap",
        height: "250px",
        margin: "70px 0",
      };
    } else if (ClientConfig.employeePhotoEnabled === false) {
      return {
        maxHeight: 260,
        width: 1300,
        overflowY: "hidden",
        overflowX: "hidden",
        position: "absolute",
        left: 308,
        top: 330,
        whiteSpace: "nowrap",
        marginTop: "20px",
        marginBottom: "20px",
      };
    } else {
      return {
        height: 410,
        width: 1320,
        overflowY: "hidden",
        overflowX: "hidden",
        position: "absolute",
        left: 308,
        top: 330,
        whiteSpace: "nowrap",
        marginTop: "20px",
        marginBottom: "20px",
      };
    }
  }

  static styleScrollrightBtn(layoutData: CheckInLayoutDTO): CSSProperties {
    if (layoutData.code === "EDForhandler") {
      return {
        position: "absolute",
        top: 100,
        right: -50,
        backgroundColor: "transparent",
        color: ClientConfig.tertiaryColor,
        width: 140,
        height: 100,
        fontSize: 60,
        borderColor: "transparent",
      };
    } else if (layoutData.code === "OfficePartner") {
      return {
        position: "absolute",
        top: "41%",
        right: 150,
        backgroundColor: "transparent",
        color: ClientConfig.tertiaryColor,
        width: 140,
        height: 100,
        fontSize: 60,
        borderColor: "transparent",
      };
    } else if (layoutData.code === "Dragsbaek") {
      return {
        position: "absolute",
        top: "43vh",
        right: "8vw",
        backgroundColor: "transparent",
        color: ClientConfig.tertiaryColor,
        width: "10vh",
        height: "10vh",
        fontSize: "6vh",
        borderColor: "transparent",
      };
    } else if (
      layoutData.code === "Flensted" ||
      layoutData.code === "FlenstedWorkshop"
    ) {
      return {
        position: "absolute",
        top: 430,
        right: 162,
        backgroundColor: "transparent",
        color: ClientConfig.tertiaryColor,
        width: 140,
        height: 100,
        fontSize: 60,
        borderColor: "transparent",
      };
    } else if (layoutData.code === "BirchEjendomme") {
      return {
        position: "absolute",
        top: "40vh",
        right: 0,
        backgroundColor: "transparent",
        color: "#dee2e6",
        width: 140,
        height: 100,
        fontSize: 60,
        borderColor: "transparent",
      };
    } else if (layoutData.code === "TotalEnergies") {
      return {
        backgroundColor: "transparent",
        color: ClientConfig.tertiaryColor,
        fontSize: 60,
        border: "none",
        float: "right",
        top: "250px",
        pointerEvents: "all",
      };
    } else {
      return {
        position: "absolute",
        top: 430,
        right: 150,
        backgroundColor: "transparent",
        color: ClientConfig.tertiaryColor,
        width: 140,
        height: 100,
        fontSize: 60,
        borderColor: "transparent",
      };
    }
  }

  static styleScrollleftBtn(layoutData: CheckInLayoutDTO): CSSProperties {
    if (layoutData.code === "EDForhandler") {
      return {
        position: "absolute",
        top: 100,
        left: -50,
        backgroundColor: "transparent",
        color: ClientConfig.tertiaryColor,
        width: 140,
        height: 100,
        fontSize: 60,
        borderColor: "transparent",
      };
    } else if (layoutData.code === "OfficePartner") {
      return {
        position: "absolute",
        top: "41%",
        left: 150,
        backgroundColor: "transparent",
        color: ClientConfig.tertiaryColor,
        width: 140,
        height: 100,
        fontSize: 60,
        borderColor: "transparent",
      };
    } else if (layoutData.code === "Dragsbaek") {
      return {
        position: "absolute",
        top: "43vh",
        left: "8vw",
        backgroundColor: "transparent",
        color: ClientConfig.tertiaryColor,
        width: "10vh",
        height: "10vh",
        fontSize: "6vh",
        borderColor: "transparent",
      };
    } else if (
      layoutData.code === "Flensted" ||
      layoutData.code === "FlenstedWorkshop"
    ) {
      return {
        position: "absolute",
        top: 430,
        left: 162,
        backgroundColor: "transparent",
        color: ClientConfig.tertiaryColor,
        width: 140,
        height: 100,
        fontSize: 60,
        borderColor: "transparent",
      };
    } else if (layoutData.code === "BirchEjendomme") {
      return {
        position: "absolute",
        top: "40vh",
        left: 0,
        backgroundColor: "transparent",
        color: "#dee2e6",
        width: 140,
        height: 100,
        fontSize: 60,
        borderColor: "transparent",
      };
    } else if (layoutData.code === "TotalEnergies") {
      return {
        backgroundColor: "transparent",
        color: ClientConfig.tertiaryColor,
        fontSize: 60,
        border: "none",
        float: "left",
        top: "250px",
        pointerEvents: "all",
      };
    } else {
      return {
        position: "absolute",
        top: 430,
        left: 150,
        backgroundColor: "transparent",
        color: ClientConfig.tertiaryColor,
        width: 140,
        height: 100,
        fontSize: 60,
        borderColor: "transparent",
      };
    }
  }

  static getEmployeeCardStyle = (
    layoutData: CheckInLayoutDTO,
    hasPhoto: boolean
  ): CSSProperties => {
    const borderColor =
      ClientConfig.mainColor === "#000000"
        ? "1px solid white"
        : ClientConfig.mainColor === "#ffffff"
        ? "1px solid black"
        : "0px";
    if (layoutData.code === "SociatyOfLife") {
      return {
        background: ClientConfig.mainColor,
        border: borderColor,
        color: ClientConfig.mainTextColor,
        justifyContent: "center",
        paddingTop: 0,
        height: "240px",

        width: "265px",
        display: "flex",
        alignItems: "center",
        flexFlow: "column nowrap",
        transition: "all .4s ease",
        margin: "30px",
        cursor: "pointer",
        overflow: "hidden",
      };
    } else if (layoutData.code === "Retograad") {
      return {
        background: ClientConfig.mainColor,
        border: borderColor,
        color: ClientConfig.mainTextColor,
        justifyContent: "center",
        padding: "0px",
        margin: "4px",
        marginTop: "0px",
        width: "180px",
        height: "165px",

        display: "flex",
        alignItems: "center",
        flexFlow: "column nowrap",
        transition: "all .4s ease",
        cursor: "pointer",
        overflow: "hidden",
      };
    } else if (layoutData.code === "Dragsbaek") {
      return {
        background: ClientConfig.mainColor,
        border: borderColor,
        color: ClientConfig.mainTextColor,
        justifyContent: "center",
        padding: "0px",

        height: hasPhoto ? "46vh" : "26.5vh",
        width: "20vw",
        display: "flex",
        alignItems: "center",
        flexFlow: "column nowrap",
        transition: "all .4s ease",
        margin: "2vw",
        cursor: "pointer",
        overflow: "hidden",
      };
    } else if (
      layoutData.code === "Flensted" ||
      layoutData.code === "FlenstedWorkshop"
    ) {
      return {
        background: ClientConfig.mainColor,
        border: borderColor,
        color: ClientConfig.mainTextColor,
        justifyContent: "center",
        padding: "0px",
        borderRadius: "16px",

        height: hasPhoto ? "350px" : "200px",
        width: "265px",
        display: "flex",
        alignItems: "center",
        flexFlow: "column nowrap",
        transition: "all .4s ease",
        margin: "30px",
        cursor: "pointer",
        overflow: "hidden",
      };
    } else if (layoutData.code === "OfficePartner") {
      return {
        background: ClientConfig.mainColor,
        borderRadius: "10px",
        border: "2px solid grey",
        color: ClientConfig.mainTextColor,
        justifyContent: "center",
        padding: "0px",

        height: hasPhoto ? "350px" : "200px",
        width: "265px",
        display: "flex",
        alignItems: "center",
        flexFlow: "column nowrap",
        transition: "all .4s ease",
        margin: "30px",
        cursor: "pointer",
        overflow: "hidden",
      };
    } else if (
      layoutData.code === "Lauritzen" ||
      layoutData.code === "Bulkers"
    ) {
      return {
        backgroundImage: `url(${"/images/TransparentButton.png"})`,
        backgroundSize: "100% 100%",
        border: borderColor,
        color: ClientConfig.mainTextColor,
        justifyContent: "center",
        padding: "0px",

        height: hasPhoto ? "350px" : "200px",
        width: "265px",
        display: "flex",
        alignItems: "center",
        flexFlow: "column nowrap",
        transition: "all .4s ease",
        margin: "30px",
        cursor: "pointer",
        overflow: "hidden",
      };
    } else if (layoutData.code === "BirchEjendomme") {
      return {
        background: ClientConfig.mainColor,
        border: borderColor,
        color: ClientConfig.mainTextColor,
        justifyContent: "center",
        padding: "0px",

        height: hasPhoto ? "350px" : "180px",
        width: "210px",
        display: "flex",
        alignItems: "center",
        flexFlow: "column nowrap",
        transition: "all .4s ease",
        margin: "10px 30px",
        cursor: "pointer",
        overflow: "hidden",
      };
    } else {
      return {
        background: ClientConfig.mainColor,
        border: borderColor,
        color: ClientConfig.mainTextColor,
        justifyContent: "center",
        padding: "0px",

        height: hasPhoto ? "350px" : "200px",
        width: "265px",
        display: "flex",
        alignItems: "center",
        flexFlow: "column nowrap",
        transition: "all .4s ease",
        margin: "30px",
        cursor: "pointer",
        overflow: "hidden",
      };
    }
  };

  static getEmployeeNameStyle = (
    layoutData: CheckInLayoutDTO
  ): CSSProperties => {
    if (layoutData.code === "SociatyOfLife") {
      return {
        fontSize: "17px",
        textAlign: "center",
        padding: "0 10px",
        wordWrap: "break-word",
        wordBreak: "break-word",
        fontWeight: "bold",
      };
    } else if (layoutData.code === "Retograad") {
      return {
        fontSize: "14px",
        fontWeight: "normal",
        paddingTop: "5px",
        paddingBottom: "0px",
        position: "absolute",
        top: "247px",
      };
    } else if (layoutData.code === "Dragsbaek") {
      return {
        fontSize: "3vh",
        whiteSpace: "initial",
        padding: "1.5vh",
        wordWrap: "break-word",
        fontWeight: "bold",
        display: "flex",
        justifyContent: "center",
        textAlign: "center",
        cursor: "pointer",
      };
    } else {
      return {
        fontSize: "20px",
        whiteSpace: "initial",
        padding: "15px",
        fontWeight: "bold",
        display: "flex",
        justifyContent: "center",
        textAlign: "center",
        cursor: "pointer",
      };
    }
  };

  static getEmployeeJobTitleStyle = (
    layoutData: CheckInLayoutDTO
  ): CSSProperties => {
    if (layoutData.code === "SociatyOfLife") {
      return {
        textAlign: "center",
        fontSize: "16px",
        padding: "0 10px",
        wordWrap: "break-word",
        wordBreak: "break-word",
        fontWeight: "bold",
      };
    } else if (layoutData.code === "Dragsbaek") {
      return {
        whiteSpace: "initial",
        display: "flex",
        justifyContent: "center",
        textAlign: "center",
        cursor: "pointer",
        minWidth: "15vw",
        fontSize: "2vh",
        padding: "0 0.5vw",
        wordWrap: "break-word",
        wordBreak: "break-word",
      };
    } else if (layoutData.code === "BirchEjendomme") {
      return {
        whiteSpace: "initial",
        display: "flex",
        justifyContent: "center",
        textAlign: "center",
        cursor: "pointer",
        minWidth: "160px",
        fontSize: "16px",
        padding: "0 10px",
        wordWrap: "break-word",
        wordBreak: "break-word",
      };
    } else {
      return {
        whiteSpace: "initial",
        display: "flex",
        justifyContent: "center",
        textAlign: "center",
        cursor: "pointer",
        minWidth: "260px",
        fontSize: "16px",
        padding: "0 10px",
        wordWrap: "break-word",
        wordBreak: "break-word",
      };
    }
  };

  static getEmployeeDepartmentTitleStyle = (
    layoutData: CheckInLayoutDTO
  ): CSSProperties => {
    if (layoutData.code === "SociatyOfLife") {
      return {
        textAlign: "center",
        fontSize: "16px",
        padding: "0 10px",
        wordWrap: "break-word",
        wordBreak: "break-word",
        fontWeight: "bold",
      };
    } else if (layoutData.code === "Retograad") {
      return {
        fontSize: "12px",
        position: "absolute",
        top: "272px",
      };
    } else if (layoutData.code === "Dragsbaek") {
      return {
        whiteSpace: "initial",
        display: "flex",
        justifyContent: "center",
        textAlign: "center",
        cursor: "pointer",
        minWidth: "15vw",
        fontSize: "2vh",
        padding: "0 0.5vw",
        wordWrap: "break-word",
        wordBreak: "break-word",
      };
    } else {
      return {
        whiteSpace: "initial",
        display: "flex",
        justifyContent: "center",
        textAlign: "center",
        cursor: "pointer",
        minWidth: "260px",
        fontSize: "16px",
        padding: "0 10px",
        wordWrap: "break-word",
        wordBreak: "break-word",
      };
    }
  };

  static getEmployeePhoneNumberStyle = (
    layoutData: CheckInLayoutDTO
  ): CSSProperties => {
    if (layoutData.code === "SociatyOfLife") {
      return {
        textAlign: "center",
        fontSize: "16px",
        padding: "0 10px",
        wordWrap: "break-word",
        wordBreak: "break-word",
        fontWeight: "bold",
      };
    } else if (layoutData.code === "Dragsbaek") {
      return {
        whiteSpace: "initial",
        display: "flex",
        justifyContent: "center",
        textAlign: "center",
        cursor: "pointer",
        minWidth: "15vw",
        fontSize: "2vh",
        padding: "0 0.5vw",
        wordWrap: "break-word",
        wordBreak: "break-word",
      };
    } else {
      return {
        whiteSpace: "initial",
        display: "flex",
        justifyContent: "center",
        textAlign: "center",
        cursor: "pointer",
        minWidth: "260px",
        fontSize: "16px",
        padding: "0 10px",
        wordWrap: "break-word",
        wordBreak: "break-word",
      };
    }
  };
  // #endregion

  //#region Date & Time
  /*-------------------*/
  static getClockStyle = (layoutData: CheckInLayoutDTO): CSSProperties => {
    if (layoutData.code === "Dragsbaek") {
      return {
        margin: "3vh 2vw 0 0",
      };
    } else if (
      layoutData.code === "SociatyOfLife" ||
      layoutData.code === "Retograad"
    ) {
      return {};
    } else if (layoutData.code === "Fundamentet") {
      return {
        margin: "30px 0 30px 40px",
      };
    } else if (
      layoutData.code === "Haugen" ||
      layoutData.code === "Lauritzen" ||
      layoutData.code === "Bulkers" ||
      layoutData.code === "LauritzenHouse"
    ) {
      return {
        display: "flex",
        flexDirection: "row",
        margin: "30px 40px 0 0",
      };
    } else if (layoutData.code === "DSStaal") {
      return {
        position: "absolute",
        left: "170px",
        bottom: "68px",
        alignItems: "flex-start",
      };
    } else {
      return {
        margin: "30px 40px 0 0",
      };
    }
  };

  static getClockDateStyle = (layoutData: CheckInLayoutDTO): CSSProperties => {
    if (layoutData.code === "Dragsbaek") {
      return {
        fontWeight: 500,
        fontSize: "4vh",
      };
    } else if (
      layoutData.code === "Flensted" ||
      layoutData.code === "FlenstedWorkshop"
    ) {
      return {
        fontWeight: 600,
        fontSize: "24px",
        color: ClientConfig.secondaryColor,
      };
    } else if (
      layoutData.code === "Haugen" ||
      layoutData.code === "Lauritzen" ||
      layoutData.code === "Bulkers" ||
      layoutData.code === "LauritzenHouse"
    ) {
      return {
        fontWeight: 600,
        fontSize: "30px",
        marginLeft: "12px",
      };
    } else if (layoutData.code === "DSStaal") {
      return {
        fontWeight: "550",
        fontSize: "25px",
        color: "lightgrey",
      };
    } else {
      return {
        fontWeight: 500,
        fontSize: "24px",
        color: ClientConfig.tertiaryTextColor,
      };
    }
  };

  static getClockTimeStyle = (layoutData: CheckInLayoutDTO): CSSProperties => {
    if (layoutData.code === "Dragsbaek") {
      return {
        fontWeight: 600,
        fontSize: "4vh",
      };
    } else if (
      layoutData.code === "Flensted" ||
      layoutData.code === "FlenstedWorkshop"
    ) {
      return {
        fontWeight: 600,
        fontSize: "24px",
        color: ClientConfig.secondaryColor,
      };
    } else if (
      layoutData.code === "Haugen" ||
      layoutData.code === "Lauritzen" ||
      layoutData.code === "Bulkers" ||
      layoutData.code === "LauritzenHouse"
    ) {
      return {
        fontWeight: 600,
        fontSize: "30px",
      };
    } else if (layoutData.code === "DSStaal") {
      return {
        fontWeight: "550",
        fontSize: "25px",
        color: "lightgrey",
      };
    } else {
      return {
        fontWeight: 600,
        fontSize: "50px",
        color: ClientConfig.tertiaryTextColor,
      };
    }
  };
  // #endregion

  //#region Language & Home
  /*-------------------------*/
  static getLanguageContainerStyle = (
    layoutData: CheckInLayoutDTO
  ): CSSProperties => {
    if (layoutData.code === "Dragsbaek") {
      return {
        display: "flex",
        flexDirection: "row",
        flexWrap: "nowrap",
        width: "20vh",
        height: "10vh",
      };
    } else if (
      layoutData.code === "Flensted" ||
      layoutData.code === "FlenstedWorkshop"
    ) {
      return {
        marginTop: "-90px",
        display: "flex",
        flexDirection: "column",
        flexWrap: "nowrap",
        width: "90px",
        height: "270px",
      };
    } else if (layoutData.code === "BirchEjendomme") {
      return {
        display: "flex",
        flexDirection: "row",
        flexWrap: "nowrap",
        width: "140px",
        height: "80px",
        position: "absolute",
        bottom: "10px",
        left: "10px",
      };
    } else if (layoutData.code === "TotalEnergies") {
      return {
        display: "flex",
        flexDirection: "row",
        flexWrap: "nowrap",
        width: "200px",
        height: "100px",
        pointerEvents: "all",
      };
    } else if (layoutData.code === "DSStaal") {
      return {
        display: "flex",
        flexDirection: "row",
        flexWrap: "nowrap",
        width: "200px",
        height: "100px",
        position: "absolute",
        top: 25,
        left: 60,
      };
    } else {
      return {
        display: "flex",
        flexDirection: "row",
        flexWrap: "nowrap",
        width: "200px",
        height: "100px",
      };
    }
  };

  static getLanguageButtonStyle = (
    layoutData: CheckInLayoutDTO
  ): CSSProperties => {
    if (layoutData.code === "Dragsbaek") {
      return {
        justifyContent: "center",
        alignItems: "center",
        width: "10vh",
        height: "10vh",
        display: "flex",
      };
    } else if (
      layoutData.code === "Flensted" ||
      layoutData.code === "FlenstedWorkshop"
    ) {
      return {
        justifyContent: "center",
        alignItems: "center",
        width: "90px",
        height: "90px",
        display: "flex",
      };
    } else if (layoutData.code === "Haugen") {
      return {
        justifyContent: "center",
        alignItems: "center",
        width: "120px",
        height: "90px",
        display: "flex",
      };
    } else {
      return {
        justifyContent: "center",
        alignItems: "center",
        width: "100px",
        height: "100px",
        display: "flex",
      };
    }
  };

  static getLanguageImageStyle = (
    layoutData: CheckInLayoutDTO,
    selected: boolean
  ): CSSProperties => {
    if (layoutData.code === "Dragsbaek") {
      return {
        width: "8vh",
        height: "8vh",
        filter: selected ? "" : "grayscale(100%)",
      };
    } else if (
      layoutData.code === "Flensted" ||
      layoutData.code === "FlenstedWorkshop"
    ) {
      return {
        display: "flex",
        alignItems: "center",
        textAlign: "center",
        fontSize: "30px",
        fontWeight: "600",

        width: "80px",
        height: "80px",
        borderRadius: "16px",
        filter: selected ? "" : "grayscale(100%)",
        backgroundColor: ClientConfig.mainColor,
        color: ClientConfig.mainTextColor,
      };
    } else if (layoutData.code === "Haugen") {
      return {
        width: "120",
        height: "90",
        filter: selected ? "" : "grayscale(100%)",
      };
    } else if (layoutData.code === "BirchEjendomme") {
      return {
        width: "60px",
        height: "60px",
        filter: selected ? "" : "grayscale(100%)",
      };
    } else if (layoutData.code === "DSStaal") {
      return {
        width: "75px",
        height: "50px",
        filter: selected ? "" : "grayscale(100%)",
      };
    } else {
      return {
        width: "80px",
        height: "80px",
        filter: selected ? "" : "grayscale(100%)",
      };
    }
  };

  static getHomeContainerStyle = (
    layoutData: CheckInLayoutDTO
  ): CSSProperties => {
    if (layoutData.code === "Dragsbaek") {
      return {
        marginRight: "1vw",
      };
    } else if (layoutData.code === "Fundamentet") {
      return {
        marginRight: "20px",
        position: "absolute",
        bottom: 0,
        left: 0,
      };
    } else if (
      layoutData.code === "Flensted" ||
      layoutData.code === "FlenstedWorkshop"
    ) {
      return {
        marginTop: "120px",
        marginRight: "20px",
      };
    } else if (layoutData.code === "BirchEjendomme") {
      return {
        position: "absolute",
        left: "0px",
        bottom: "0px",
      };
    } else if (layoutData.code === "TotalEnergies") {
      return {
        position: "fixed",
        top: "1px",
        left: "calc(66% - 152px)",
        marginRight: "20px",
        pointerEvents: "all",
      };
    } else {
      return {
        marginRight: "20px",
      };
    }
  };

  static getHomeButtonStyles = (
    layoutData: CheckInLayoutDTO
  ): Partial<IButtonStyles> => {
    if (layoutData.code === "EDForhandler") {
      return {
        label: {
          fontSize: "22px",
          fontFamily: "Roboto",
          fontWeight: 600,
        },
        root: {
          height: "50px",
          width: "150px",
          border: "0px",
          backgroundColor: "#E3792B",
          color: ClientConfig.secondaryTextColor,
          borderRadius: "40px",
          marginBottom: "50px",
          marginLeft: "20px",
        },
        rootHovered: {
          border: "0px",
          backgroundColor: "#E3792B",
          color: ClientConfig.secondaryTextColor,
        },
        rootPressed: {
          border: "0px",
          backgroundColor: "#E3792B",
          color: ClientConfig.secondaryTextColor,
        },
        icon: {
          fontSize: "22px",
        },
      };
    } else if (layoutData.code === "Kjaergaard") {
      return {
        label: {
          fontSize: "22px",
          fontWeight: 600,
        },
        root: {
          height: "50px",
          width: "150px",
          border: "0px",
          backgroundColor: ClientConfig.secondaryColor,
          color: ClientConfig.secondaryTextColor,
        },
        rootHovered: {
          border: "0px",
          backgroundColor: ClientConfig.secondaryColor,
          color: ClientConfig.secondaryTextColor,
        },
        rootPressed: {
          border: "0px",
          backgroundColor: ClientConfig.secondaryColor,
          color: ClientConfig.secondaryTextColor,
        },
        icon: {
          fontSize: "30px",
        },
      };
    } else if (layoutData.code === "Dragsbaek") {
      return {
        label: {
          fontSize: "4vh",
          fontFamily: "Roboto",
          fontWeight: 300,
        },
        root: {
          height: "10vh",
          width: "10vw",
          border: "0px",
          backgroundColor: ClientConfig.secondaryColor,
          color: ClientConfig.secondaryTextColor,
        },
        rootHovered: {
          border: "0px",
          backgroundColor: ClientConfig.secondaryColor,
          color: ClientConfig.secondaryTextColor,
        },
        rootPressed: {
          border: "0px",
          backgroundColor: ClientConfig.secondaryColor,
          color: ClientConfig.secondaryTextColor,
        },
        icon: {
          fontSize: "4vh",
        },
      };
    } else if (
      layoutData.code === "Flensted" ||
      layoutData.code === "FlenstedWorkshop"
    ) {
      return {
        label: {
          fontSize: "27px",
          fontFamily: "Roboto",
          fontWeight: 300,
        },
        root: {
          height: "68px",
          width: "152px",
          border: "0px",
          backgroundColor: "transparent",
          color: ClientConfig.secondaryTextColor,
        },
        rootHovered: {
          border: "0px",
          backgroundColor: "transparent",
          color: ClientConfig.secondaryTextColor,
        },
        rootPressed: {
          border: "0px",
          backgroundColor: "transparent",
          color: ClientConfig.secondaryTextColor,
        },
        icon: {
          fontSize: "22px",
        },
      };
    } else if (
      layoutData.code === "Lauritzen" ||
      layoutData.code === "Bulkers"
    ) {
      return {
        label: {
          fontSize: "27px",
          fontFamily: "Calibri",
          fontWeight: 300,
        },
        root: {
          height: "68px",
          width: "152px",
          border: "0px",
          backgroundColor: "transparent",
          backgroundImage: `url(${"/images/TransparentButton.png"})`,
          backgroundSize: "100% 100%",
          color: ClientConfig.secondaryTextColor,
        },
        rootHovered: {
          border: "0px",
          backgroundColor: "transparent",
          backgroundImage: `url(${"/images/TransparentButton.png"})`,
          backgroundSize: "100% 100%",
          color: ClientConfig.secondaryTextColor,
        },
        rootPressed: {
          border: "0px",
          backgroundColor: "transparent",
          backgroundImage: `url(${"/images/TransparentButton.png"})`,
          backgroundSize: "100% 100%",
          color: ClientConfig.secondaryTextColor,
        },
        icon: {
          fontSize: "22px",
        },
      };
    } else if (layoutData.code === "BirchEjendomme") {
      return {
        label: {
          fontSize: "20px",
          fontFamily: "Roboto",
          fontWeight: 300,
        },
        root: {
          height: "60px",
          minWidth: "60px",
          border: "0px",
          padding: "0px",
          backgroundColor: ClientConfig.secondaryColor,
          color: ClientConfig.secondaryTextColor,
        },
        rootHovered: {
          border: "0px",
          backgroundColor: ClientConfig.secondaryColor,
          color: ClientConfig.secondaryTextColor,
        },
        rootPressed: {
          border: "0px",
          backgroundColor: ClientConfig.secondaryColor,
          color: ClientConfig.secondaryTextColor,
        },
        icon: {
          fontSize: "20px",
        },
      };
    } else {
      return {
        label: {
          fontSize: "27px",
          fontFamily: "Roboto",
          fontWeight: 300,
        },
        root: {
          height: "68px",
          width: "152px",
          border: "0px",
          backgroundColor: ClientConfig.secondaryColor,
          color: ClientConfig.secondaryTextColor,
        },
        rootHovered: {
          border: "0px",
          backgroundColor: ClientConfig.secondaryColor,
          color: ClientConfig.secondaryTextColor,
        },
        rootPressed: {
          border: "0px",
          backgroundColor: ClientConfig.secondaryColor,
          color: ClientConfig.secondaryTextColor,
        },
        icon: {
          fontSize: "22px",
        },
      };
    }
  };
  // #endregion

  //#region Logo
  /*-------------------------*/
  static getLogoStyle = (layoutData: CheckInLayoutDTO): CSSProperties => {
    if (layoutData.code === "Kjaergaard") {
      return {
        maxHeight: "100px",
        margin: "40px 0 0 20px",
        width: "240px",
        height: "66px",
      };
    } else if (layoutData.code === "Dragsbaek") {
      return {
        maxHeight: "12vh",
        margin: "1vh 0 0 1vh",
      };
    } else if (
      layoutData.code === "Flensted" ||
      layoutData.code === "FlenstedWorkshop"
    ) {
      return {
        width: "400px",
        marginTop: "20px",
      };
    } else if (
      layoutData.code === "Fundamentet" ||
      layoutData.code === "SociatyOfLife" ||
      layoutData.code === "Retograad"
    ) {
      return {
        maxHeight: "100px",
        margin: "20px 20px 0 0",
      };
    } else if (layoutData.code === "Haugen") {
      return {
        maxWidth: "12vw",
        marginRight: "5px",
        marginBottom: "5px",
        position: "absolute",
      };
    } else {
      return {
        maxHeight: "100px",
        margin: "20px 0 0 20px",
      };
    }
  };
  // #endregion

  //#region Header & Footer
  /*-------------------------*/
  static getHeaderContainerStyle = (
    layoutData: CheckInLayoutDTO,
    currentPage: PageType
  ): CSSProperties => {
    if (layoutData.code === "Dragsbaek") {
      return {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        fontWeight: "bold",
        fontSize: "6vh",
        width: "100%",
        color: ClientConfig.tertiaryTextColor,
        marginTop:
          currentPage === "FrontPage" || currentPage === "MessagePage"
            ? "0vh"
            : "-10vh",
      };
    } else if (
      layoutData.code === "Flensted" ||
      layoutData.code === "FlenstedWorkshop"
    ) {
      return {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        fontWeight: "bold",
        fontSize: "70px",
        height: "200px",
        width: "100%",
        color: ClientConfig.tertiaryTextColor,
        marginTop:
          currentPage === "FrontPage" || currentPage === "MessagePage"
            ? "0px"
            : currentPage === "DepartmentPage"
            ? "-120px"
            : "-150px",
      };
    } else if (layoutData.code === "Kjaergaard") {
      return {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        fontWeight: "bold",
        fontSize: "70px",
        height: "200px",
        width: "100%",
        color: ClientConfig.tertiaryTextColor,
        marginTop:
          currentPage === "FrontPage" || currentPage === "MessagePage"
            ? "70px"
            : "-270px",
      };
    } else if (
      layoutData.code === "Visionhouse" ||
      layoutData.code === "VismaHouse" ||
      layoutData.code === "SSIDiagnosticaHouse" ||
      layoutData.code === "CSolutionHouse"
    ) {
      return {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        fontWeight: "bold",
        fontSize: "70px",
        height: "200px",
        width: "100%",
        color: ClientConfig.tertiaryTextColor,
        marginTop: currentPage === "MessagePage" ? "0px" : "-150px",
      };
    } else if (layoutData.code === "FibiaHaslevHouse") {
      return {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        fontWeight: "bold",
        fontSize: "70px",
        height: "200px",
        width: "100%",
        color: ClientConfig.tertiaryTextColor,
        marginTop: "0px",
      };
    } else if (layoutData.code === "BirchEjendomme") {
      return {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        fontWeight: "bold",
        fontSize: "50px",
        height: "200px",
        width: "100%",
        color: ClientConfig.tertiaryTextColor,
        marginTop:
          currentPage === "FrontPage" || currentPage === "MessagePage"
            ? "0px"
            : "-150px",
        paddingTop: currentPage === "FrontPage" ? "0px" : "150px",
        whiteSpace: "pre-wrap",
      };
    } else if (layoutData.code === "TotalEnergies") {
      return {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        fontWeight: "bold",
        fontSize: "60px",
        height: "200px",
        width: "100%",
        color: ClientConfig.tertiaryTextColor,
        marginTop:
          currentPage === "FrontPage" ||
          currentPage === "MessagePage" ||
          currentPage === "PhoneNumberPage"
            ? "0px"
            : "-150px",
        whiteSpace: "pre-wrap",
      };
    } else {
      return {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        fontWeight: "bold",
        fontSize: "60px",
        height: "200px",
        width: "100%",
        color: ClientConfig.tertiaryTextColor,
        marginTop:
          currentPage === "FrontPage" || currentPage === "MessagePage"
            ? "0px"
            : "-150px",
        whiteSpace: "pre-wrap",
      };
    }
  };

  static getHeaderStyle = (layoutData: CheckInLayoutDTO): CSSProperties => {
    if (
      layoutData.code === "Flensted" ||
      layoutData.code === "FlenstedWorkshop"
    ) {
      return {
        alignSelf: "flex-end",
        flexFlow: "row nowrap",
        width: "calc(50% + 200px)",
        display: "flex",
        justifyContent: "space-between",
      };
    } else if (
      layoutData.code === "SociatyOfLife" ||
      layoutData.code === "Retograad"
    ) {
      return {
        flexFlow: "row nowrap",
        width: "100%",
        height: "100px",
        display: "flex",
        justifyContent: "space-between",
      };
    } else if (layoutData.code === "ErhvershusMidt") {
      return {
        flexFlow: "row nowrap",
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        backgroundColor: ClientConfig.mainColor,
      };
    } else if (layoutData.code === "Fundamentet") {
      return {
        flexFlow: "row-reverse nowrap",
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
      };
    } else if (
      layoutData.code === "Lauritzen" ||
      layoutData.code === "Bulkers" ||
      layoutData.code === "LauritzenHouse"
    ) {
      return {
        paddingTop: "20px",
        flexFlow: "row nowrap",
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
      };
    } else if (layoutData.code === "TotalEnergies") {
      return {
        flexFlow: "row nowrap",
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        height: "15%",
      };
    } else if (layoutData.code === "DSStaal") {
      return {
        flexFlow: "row nowrap",
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        height: "111px",
      };
    } else {
      return {
        flexFlow: "row nowrap",
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
      };
    }
  };

  static getSubHeaderStyle = (layoutData: CheckInLayoutDTO): CSSProperties => {
    if (layoutData.code === "Dragsbaek") {
      return {
        fontSize: "5vh",
      };
    } else if (layoutData.code === "BirchEjendomme") {
      return {
        fontSize: "30px",
      };
    } else {
      return {};
    }
  };

  static getFooterParentStyle = (
    layoutData: CheckInLayoutDTO
  ): CSSProperties => {
    if (layoutData.code === "TotalEnergies") {
      return {
        flexFlow: "row nowrap",
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        height: "10%",
        position: "absolute",
        bottom: "0px",
        pointerEvents: "none",
      };
    } else
      return {
        flexFlow: "row nowrap",
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
      };
  };

  static getFooterStyle = (layoutData: CheckInLayoutDTO): CSSProperties => {
    if (
      layoutData.code === "OfficePartner" ||
      layoutData.code === "EDForhandler" ||
      layoutData.code === "Reisswolf" ||
      layoutData.code === "SociatyOfLife" ||
      layoutData.code === "Retograad" ||
      layoutData.code === "Fundamentet"
    ) {
      return {
        width: "100%",
        height: "100px",
        display: "flex",
        flexFlow: "row",
        justifyContent: "space-between",
        alignItems: "flex-end",
      };
    } else if (
      layoutData.code === "Flensted" ||
      layoutData.code === "FlenstedWorkshop"
    ) {
      return {
        top: "635px",
        position: "absolute",
        alignSelf: "flex-start",
        display: "flex",
        flexFlow: "row",
        justifyContent: "space-between",
        alignItems: "flex-end",
      };
    } else {
      return {
        alignSelf: "flex-start",
        display: "flex",
        flexFlow: "row",
        justifyContent: "space-between",
        alignItems: "flex-end",
      };
    }
  };
  // #endregion

  //#region Header & Footer
  /*-------------------------*/
  static getNumberContainerStyle = (
    layoutData: CheckInLayoutDTO
  ): CSSProperties => {
    if (layoutData.code === "TotalEnergies") {
    }
    return {};
  };
  // #endregion

  /** Not used for static class. */
  render() {
    return <div></div>;
  }
}
