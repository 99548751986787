export const ZebraPrintGuestCard = async (
  printerSettings: CheckInPrinterSettingsDTO,
  fullName: string,
  companyName: string,
  phoneNumber: string,
  host: string,
  badgeNumber: string = ""
) => {
  const now = new Date();
  const zplTemplateWithReplacedFields = printerSettings.printTemplate
    .replace("{Name}", fullName)
    .replace("{Company}", companyName)
    .replace("{Phone}", phoneNumber)
    .replace("{Host}", host)
    .replace(
      "{Date}",
      now.getFullYear() +
        "/" +
        String(now.getMonth() + 1).padStart(2, "0") +
        "/" +
        String(now.getDate()).padStart(2, "0") +
        " " +
        String(now.getHours()).padStart(2, "0") +
        ":" +
        String(now.getMinutes()).padStart(2, "0")
    )
    .replace("{BadgeNumber}", badgeNumber);

  const printData = {
    name: "", // For legacy printer driver (totalenergies)
    company: "", // For legacy printer driver (totalenergies)
    phone: "", // For legacy printer driver (totalenergies)
    connectionType: printerSettings.connectionType,
    printerIp: printerSettings.networkIpAddress, // if using network connection
    port: printerSettings.networkPort, // if using network connection
    usbPrinterName: printerSettings.usbPrinterName, // if using USB connection
    zplTemplate: zplTemplateWithReplacedFields,
  };
  const controller = new AbortController();
  const timeoutId = setTimeout(() => controller.abort(), 2000);
  await fetch(printerSettings.localPrinterServiceUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(printData),
    signal: controller.signal,
  })
    .then((data) => {
      if (data.status === 200) console.log("Successfully printed");
      else
        console.log(
          "Could not print: " + data.status + " - " + data.statusText
        );
    })
    .catch((error) => {
      console.error(error);
    });
  clearTimeout(timeoutId);

  return;
};
