import React, { Component } from "react";
import ClientConfig from "../config/clientConfig";
import store from "../configureStore";
import StylesUtil from "./StylesUtil";
import UrlConfig from "../config/urlConfig";
import { setCurrentPage } from "../store/Flow/actions";
//import { resetStoreState, setCurrentFlow, setCurrentPage, setPhoneNumberExists } from '../store/Flow/actions';

//interface IDispatchProps {
//    setCurrentPage: typeof setCurrentPage;
//    setCurrentFlow: typeof setCurrentFlow;
//    setPhoneNumberExists: typeof setPhoneNumberExists;
//    resetStoreState: typeof resetStoreState;
//}

//type Props = IDispatchProps;

export default class RedirectUtil extends Component {
  /** Very hacky. */
  static getUrl(layoutCode: string, baseUrl: string): string {
    var url = baseUrl;
    switch (layoutCode) {
      case "Vision":
      case "CSAM":
      case "Risma":
      case "Rovsing":
      case "EssentialSecurity":
        url += "70";
        break;
      case "SSIDiagnostica":
      case "ZigNaturTraining":
      case "SmartPractice":
      case "SolidConsult":
      case "KimKnirkholtJensen":
      case "CoachSpirit":
        url += "2088";
        break;
      case "CSolution":
      case "TMSAS":
      case "BiggerBrand":
      case "KMAutomation":
      case "OneCode":
        url += "933F62A1-94E5-486E-A38E-F333B76D9030";
        break;
      case "FibiaHaslev":
      case "FibiaAndel":
      case "FibiaMuseum":
        url += "F3B5B4F1-A6A6-415B-A3F5-FE48B8528AEB";
        break;
      case "Lauritzen":
      case "Bulkers":
        url += "5942F414-EC70-4B4D-B761-78CA5A3D4E5E";
        break;
      case "economic":
      case "INTEMPUS":
      case "temponizer":
      case "FirstAgenda":
      case "plandisc":
      case "upodi":
      case "IMS":
      case "sofus":
      case "XFlow":
        url += "BB6BD1CB-BAC6-45BF-95F2-805F3E471D3E";
        break;
      case "Dinex":
      case "TML":
        url += "6B488415-A136-4EB1-BD7E-91DED53E6232";
        break;
    }
    url = this.addDeviceId(url);
    return url;
  }

  /** Very hacky. */
  static getLayout(layoutCode: string, baseUrl: string): JSX.Element {
    let key = layoutCode + "Key";
    //console.log(key);
    switch (layoutCode) {
      case "Vision":
        return (
          <div
            key={key}
            style={this.styleCard(
              ClientConfig.mainColor,
              0,
              50,
              400,
              180,
              true
            )}
            onClick={() => this.goToSolution("47", baseUrl)}
          >
            <img
              style={this.styleImage(5, 5, 0, 0)}
              src={window.location.origin + "/images/VisionhouseGreenLogo.png"}
              alt="Vision button"
            />
          </div>
        );
      case "CSAM":
        return (
          <div
            key={key}
            style={this.styleCard(
              ClientConfig.mainColor,
              210,
              212.5,
              600,
              180,
              true
            )}
            onClick={() => this.goToSolution("51", baseUrl)}
          >
            <img
              style={this.styleImage(5, 5, 0, 0)}
              src={window.location.origin + "/images/Omda.png"}
              alt="OMDA button"
            />
          </div>
        );
      case "Risma":
        return (
          <div
            key={key}
            style={this.styleCard(
              ClientConfig.mainColor,
              0,
              575,
              400,
              180,
              true
            )}
            onClick={() => this.goToSolution("49", baseUrl)}
          >
            <img
              style={this.styleImage(5, 5, 0, 0)}
              src={window.location.origin + "/images/RISMAlogo.png"}
              alt="Risma button"
            />
          </div>
        );
      case "Rovsing":
        return (
          <div
            key={key}
            style={this.styleCard(
              ClientConfig.mainColor,
              0,
              1100,
              400,
              180,
              true
            )}
            onClick={() => this.goToSolution("48", baseUrl)}
          >
            <img
              style={this.styleImage(5, 5, 0, 0)}
              src={window.location.origin + "/images/ROVSINGlogo.png"}
              alt="Rovsing button"
            />
          </div>
        );
      case "EssentialSecurity":
        return (
          <div
            key={key}
            style={this.styleCard(
              ClientConfig.mainColor,
              210,
              937.5,
              400,
              180,
              true
            )}
            onClick={() => this.goToSolution("52", baseUrl)}
          >
            <img
              style={this.styleImage(5, 5, 0, 0)}
              src={window.location.origin + "/images/ESSENTIALSECURITYlogo.png"}
              alt="Essential Security button"
            />
          </div>
        );

      case "SSIDiagnostica":
        return (
          <div
            key={key}
            style={this.styleCard(
              ClientConfig.mainColor,
              0,
              230,
              600,
              180,
              false
            )}
            onClick={() => this.goToSolution("2078", baseUrl)}
          >
            <img
              style={this.styleImage(5, 5, 5, 5)}
              src={window.location.origin + "/images/SSIDiagnosticaLogo.jpg"}
              alt="SSI Diagnostica button"
            />
          </div>
        );
      case "SmartPractice":
        return (
          <div
            key={key}
            style={this.styleCard(
              ClientConfig.mainColor,
              0,
              900,
              400,
              180,
              false
            )}
            onClick={() => this.goToSolution("2083", baseUrl)}
          >
            <img
              style={this.styleImage(5, 5, 5, 5)}
              src={window.location.origin + "/images/SmartPracticeLogo.jpg"}
              alt="Smart Practice button"
            />
          </div>
        );
      case "CoachSpirit":
        return (
          <div
            key={key}
            style={this.styleCard(
              ClientConfig.mainColor,
              210,
              230,
              240,
              160,
              false
            )}
            onClick={() => this.goToSolution("2087", baseUrl)}
          >
            <img
              style={this.styleImage(5, 5, 5, 5)}
              src={window.location.origin + "/images/CoachSpiritLogo.png"}
              alt="Coach Spirit button"
            />
          </div>
        );
      case "ZigNaturTraining":
        return (
          <div
            key={key}
            style={this.styleCard(
              ClientConfig.mainColor,
              210,
              507,
              240,
              160,
              true
            )}
            onClick={() => this.goToSolution("2082", baseUrl)}
          >
            <img
              style={this.styleImage(5, 5, 5, 5)}
              src={window.location.origin + "/images/ZigNaturTrainingLogo.jpg"}
              alt="Zig Natur Training button"
            />
          </div>
        );
      case "SolidConsult":
        return (
          <div
            key={key}
            style={this.styleCard(
              ClientConfig.mainColor,
              210,
              783,
              240,
              160,
              true
            )}
            onClick={() => this.goToSolution("2084", baseUrl)}
          >
            <img
              style={this.styleImage(5, 5, 5, 5)}
              src={window.location.origin + "/images/SolidConsultLogo.jpg"}
              alt="Solid Consult button"
            />
          </div>
        );
      case "KimKnirkholtJensen":
        return (
          <div
            key={key}
            style={this.styleCard(
              ClientConfig.mainColor,
              210,
              1060,
              240,
              160,
              true
            )}
            onClick={() => this.goToSolution("2085", baseUrl)}
          >
            <img
              style={this.styleImage(5, 5, 5, 5)}
              src={
                window.location.origin + "/images/KimKnirkholtJensenLogo.jpg"
              }
              alt="Kim Knirkholt Jensen button"
            />
          </div>
        );

      case "CSolution":
        return (
          <div
            key={key}
            style={this.styleCard(
              ClientConfig.mainColor,
              100,
              195,
              580,
              340,
              true
            )}
            onClick={() =>
              this.goToSolution("98729571-1B22-4A3B-A67A-7C7F4EC7116B", baseUrl)
            }
          >
            <img
              style={this.styleImage(5, 5, 0, 0)}
              src={window.location.origin + "/images/CSolution.png"}
              alt="C-Solution button"
            />
          </div>
        );
      case "OneCode":
        return (
          <div
            key={key}
            style={this.styleCard(
              ClientConfig.mainColor,
              100,
              800,
              580,
              340,
              true
            )}
            onClick={() =>
              this.goToSolution("4CC5AFA4-7259-4125-A6BF-FDD8D9B01499", baseUrl)
            }
          >
            <img
              style={this.styleImage(5, 5, 0, 0)}
              src={window.location.origin + "/images/OneCode.png"}
              alt="OneCode button"
            />
          </div>
        );

      case "FibiaHaslev":
        return (
          <div
            key={key}
            style={this.styleCardV2()}
            onClick={() =>
              this.goToSolution("34617186-25D1-4011-86A8-E155EEE0962D", baseUrl)
            }
          >
            <img
              style={this.styleImageV2()}
              src={window.location.origin + "/images/FibiaWaoo.png"}
              alt="Fibia Haslev button"
            />
          </div>
        );
      case "FibiaAndel":
        return (
          <div
            key={key}
            style={this.styleCardV2()}
            onClick={() =>
              this.goToSolution("F26A5F28-42D2-4954-B172-5883F4DC57D3", baseUrl)
            }
          >
            <img
              style={this.styleImageV2()}
              src={window.location.origin + "/images/FibiaAndel.png"}
              alt="Fibia Andel button"
            />
          </div>
        );
      case "FibiaMuseum":
        return (
          <div
            key={key}
            style={this.styleCardV2()}
            onClick={() =>
              this.goToSolution("3B9587D4-A2FA-4722-BDBF-6829DCB4DCD5", baseUrl)
            }
          >
            <img
              style={this.styleImageV2()}
              src={window.location.origin + "/images/FibiaMuseum.png"}
              alt="Fibia Museum button"
            />
          </div>
        );

      case "Lauritzen":
        return (
          <div
            key={key}
            style={this.styleCard("", 185, 150, 300, 160, true)}
            onClick={() =>
              this.goToSolution("36658F50-2400-4BA3-BB6B-C54C0651ADE0", baseUrl)
            }
          >
            <div
              style={{
                width: "100%",
                height: "100%",
                backgroundSize: "100% 100%",
                backgroundImage: `url(${"/images/TransparentButton.png"})`,
              }}
            >
              <img
                style={this.styleImage(5, 5, 0, 0)}
                src={window.location.origin + "/images/Lauritzen.png"}
                alt="Lauritzen button"
              />
            </div>
          </div>
        );
      case "Bulkers":
        return (
          <div
            key={key}
            style={this.styleCard("", 185, 1080, 300, 160, true)}
            onClick={() =>
              this.goToSolution("6FC77C71-43D1-4B07-B194-D1EEE936F7FC", baseUrl)
            }
          >
            <div
              style={{
                width: "100%",
                height: "100%",
                backgroundSize: "100% 100%",
                backgroundImage: `url(${"/images/TransparentButton.png"})`,
              }}
            >
              <img
                style={this.styleImage(5, 5, 0, 0)}
                src={window.location.origin + "/images/DealEnergy.png"}
                alt="Lauritzen button"
              />
            </div>
          </div>
        );

      case "economic":
        return (
          <div
            key={key}
            style={this.styleCard("transparent", 0, 70, 360, 120, false)}
            onClick={() =>
              this.goToSolution("04F6AF76-A2B4-4C48-9FB3-8F7092BD9359", baseUrl)
            }
          >
            <img
              style={this.styleImage(5, 5, 5, 5)}
              src={window.location.origin + "/images/economicLogo.png"}
              alt="E-conomic button"
            />
          </div>
        );
      case "INTEMPUS":
        return (
          <div
            key={key}
            style={this.styleCard("transparent", 0, 570, 360, 120, false)}
            onClick={() =>
              this.goToSolution("91182CC4-A2A6-45D6-9E62-37FFC8596BB1", baseUrl)
            }
          >
            <img
              style={this.styleImage(5, 5, 5, 5)}
              src={window.location.origin + "/images/INTEMPUSLogo.png"}
              alt="INTEMPUS button"
            />
          </div>
        );
      case "temponizer":
        return (
          <div
            key={key}
            style={this.styleCard("transparent", 0, 1070, 360, 120, false)}
            onClick={() =>
              this.goToSolution("D928BBE8-6902-4392-B953-F77BB152367D", baseUrl)
            }
          >
            <img
              style={this.styleImage(5, 5, 5, 5)}
              src={window.location.origin + "/images/temponizerLogo.png"}
              alt="Temponizer button"
            />
          </div>
        );
      case "FirstAgenda":
        return (
          <div
            key={key}
            style={this.styleCard("transparent", 150, 70, 360, 120, false)}
            onClick={() =>
              this.goToSolution("D852EC78-CF4C-4E8E-B5EC-796C59006CD2", baseUrl)
            }
          >
            <img
              style={this.styleImage(5, 5, 5, 5)}
              src={window.location.origin + "/images/FirstAgendaLogo.png"}
              alt="FirstAgenda button"
            />
          </div>
        );
      case "plandisc":
        return (
          <div
            key={key}
            style={this.styleCard("transparent", 150, 570, 360, 120, false)}
            onClick={() =>
              this.goToSolution("59186330-9A00-466B-8BFA-057DE272563B", baseUrl)
            }
          >
            <img
              style={this.styleImage(5, 5, 5, 5)}
              src={window.location.origin + "/images/PlandiscLogo.png"}
              alt="Plandisc button"
            />
          </div>
        );
      case "upodi":
        return (
          <div
            key={key}
            style={this.styleCard("transparent", 150, 1070, 360, 120, false)}
            onClick={() =>
              this.goToSolution("897B7AB0-2784-4F7F-8A05-0F71F93D98C8", baseUrl)
            }
          >
            <img
              style={this.styleImage(5, 5, 5, 5)}
              src={window.location.origin + "/images/upodiLogo.png"}
              alt="Upodi button"
            />
          </div>
        );
      case "IMS":
        return (
          <div
            key={key}
            style={this.styleCard("transparent", 300, 70, 360, 120, false)}
            onClick={() =>
              this.goToSolution("E26A99BD-6611-4148-8DAA-D375C82D05D7", baseUrl)
            }
          >
            <img
              style={this.styleImage(5, 5, 5, 5)}
              src={window.location.origin + "/images/IMSLogo.png"}
              alt="IMS button"
            />
          </div>
        );
      case "sofus":
        return (
          <div
            key={key}
            style={this.styleCard("transparent", 300, 570, 360, 120, false)}
            onClick={() =>
              this.goToSolution("3B6CEE0B-3384-4210-8101-BA88D1288215", baseUrl)
            }
          >
            <img
              style={this.styleImage(5, 5, 5, 5)}
              src={window.location.origin + "/images/sofusLogo.png"}
              alt="Sofus button"
            />
          </div>
        );
      case "XFlow":
        return (
          <div
            key={key}
            style={this.styleCard("transparent", 300, 1070, 360, 120, false)}
            onClick={() =>
              this.goToSolution("C93D9363-731A-4459-964E-B1F0FBC35654", baseUrl)
            }
          >
            <img
              style={this.styleImage(5, 5, 5, 5)}
              src={window.location.origin + "/images/XFlowLogo.png"}
              alt="XFlow button"
            />
          </div>
        );
      case "Dinex":
        return (
          <div
            key={key}
            style={this.styleCard("transparent", 300, 250, 300, 160, true)}
            onClick={() => this.goToSolution("2072", baseUrl)}
          >
            <img
              style={this.styleImage(5, 5, 5, 5)}
              src={window.location.origin + "/images/Dinex_Logo.jpg"}
              alt="Dinex button"
            />
          </div>
        );
      case "TML":
        return (
          <div
            key={key}
            style={this.styleCard("transparent", 300, 950, 300, 160, true)}
            onClick={() =>
              this.goToSolution("EB3F943A-E9FC-4FCC-9A46-514222E83C1B", baseUrl)
            }
          >
            <img
              style={this.styleImage(5, 5, 5, 5)}
              src={window.location.origin + "/images/TML_Logo.jpg"}
              alt="TML button"
            />
          </div>
        );
    }
  }

  private static styleCard(
    cardColor: string,
    top: number,
    left: number,
    width: number,
    height: number,
    border: boolean
  ): React.CSSProperties {
    if (cardColor && cardColor.length > 0) {
      return {
        position: "absolute",
        left: left,
        top: top,
        width: width,
        height: height,
        border: cardColor === "#ffffff" && border ? "1px solid black" : "",
        backgroundColor: cardColor,
      };
    } else {
      return {
        position: "absolute",
        left: left,
        top: top,
        width: width,
        height: height,
      };
    }
  }

  private static styleCardV2(): React.CSSProperties {
    return { margin: "2%" };
  }

  private static styleImage(
    leftMargin: number,
    rightMargin: number,
    topMargin: number,
    bottomMargin: number
  ): React.CSSProperties {
    let maxWidth = 100 - (leftMargin + rightMargin) + "%";
    let maxHeight = 100 - (topMargin + bottomMargin) + "%";
    return {
      maxHeight: maxHeight,
      maxWidth: maxWidth,
      width: "auto",
      height: "auto",
      position: "absolute",
      top: topMargin + "%",
      bottom: bottomMargin + "%",
      left: leftMargin + "%",
      right: rightMargin + "%",
      margin: "auto",
    };
  }

  private static styleImageV2(): React.CSSProperties {
    return {
      width: "100%",
    };
  }

  private static goToSolution(number: string, baseUrl: string) {
    //const { layoutData, currentFlow, setCurrentFlow, setCurrentPage } = this.props

    //if (layoutData.code === "FibiaMuseum") {
    //    setCurrentPage('PhoneNumberPage' as PageType)
    //    setCurrentFlow('CheckinNoAppointment' as FlowType)
    //    console.log("her")
    //    console.log(currentFlow)
    //}

    var url = baseUrl + number;
    url = this.addDeviceId(url);
    if (store.getState().localization.language)
      url += "&lang=" + store.getState().localization.language;
    console.log(url);
    window.location.assign(url);
  }

  private static addDeviceId(url: string): string {
    let link = window.location.href;
    let deviceIds = link.match("&deviceId(.*)");
    if (deviceIds && deviceIds.length > 0) {
      url += deviceIds[0];
    }
    return url;
  }

  /** Not used for static class. */
  render() {
    return <div></div>;
  }
}
